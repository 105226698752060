/* FONTS */
/* Oswald */
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
/* Open Sans */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');


/* HEADER INTERFACE */
/* NOTE: Dropdowns will likely need to be replaced soon as they're particularly resistant to styling on a number of browsers */
#Fork-Header {
  width: 100%;
  min-height: 200px;
  background-size: cover;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("https://lithi.io/file/Q8du.jpg") no-repeat center center fixed;
  background-attachment: fixed;
  transform: translate3d(0,0,0);
  user-select: none;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20);
}

#Logo {
  color: #FFF;
  text-align: center;
  padding-top: 0px;
  font-family: "Oswald", sans-serif;
  font-size: 80px;
}

#Logo img {
  pointer-events: none;
  transform: translate(-10px, 30px);
}

#Toolbar-Wrapper {
  user-select: none;
  text-align: center;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))
}

#SingleFork-Toolbar-Wrapper {
  text-align: left;
  padding: 5px 10px 5px 10px;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))
}

.counterWrapper span {
  font-weight: bold;
}

.counterWrapper button {
  color: gainsboro;
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.counterWrapper button:hover {
  color: orange;
}

#refreshButton {
  cursor:pointer;
  height: 30px;
  width: 30px;
  border:none;
  background-color: transparent;
  background-size: cover;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAwIDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTI1LDQ5YzAsMTQuMzM3LDExLjY2MywyNiwyNiwyNnMyNi0xMS42NjMsMjYtMjZjMC02LjQ5OS0yLjQxMS0xMi43Mi02Ljc4OC0xNy41MThjLTAuOTMxLTEuMDIxLTIuNTEzLTEuMDk0LTMuNTMxLTAuMTYyICBjLTEuMDIxLDAuOTMxLTEuMDkzLDIuNTEyLTAuMTYyLDMuNTMxQzcwLjA1NCwzOC43MjcsNzIsNDMuNzUxLDcyLDQ5YzAsMTEuNTc5LTkuNDIxLDIxLTIxLDIxcy0yMS05LjQyMS0yMS0yMSAgYzAtMTAuNzc4LDguMTY2LTE5LjY3NiwxOC42MzMtMjAuODU3bC0yLjUyMSwyLjc1MWMtMC45MzQsMS4wMTktMC44NjQsMi42LDAuMTUzLDMuNTMyYzAuNDgsMC40NCwxLjA4NSwwLjY1NywxLjY4OCwwLjY1NyAgYzAuNjc3LDAsMS4zNTEtMC4yNzIsMS44NDQtMC44MTFsNi45ODYtNy42MjNjMC41MDMtMC41NDksMC43MzUtMS4yOTIsMC42MzQtMi4wMjljLTAuMTAxLTAuNzM2LTAuNTI0LTEuMzktMS4xNTYtMS43ODMgIGwtOC43NzktNS40NjNjLTEuMTY5LTAuNzI3LTIuNzEzLTAuMzctMy40NDMsMC44MDNjLTAuNzI5LDEuMTcyLTAuMzcsMi43MTQsMC44MDMsMy40NDNsMi42MTYsMS42MjhDMzQuNzkxLDI0Ljk4MiwyNSwzNS44NjUsMjUsNDkgIHoiPjwvcGF0aD48L3N2Zz4=");
  filter: invert(100%);
}

#refreshButton:hover {
  filter: invert(80%);
}

#Fork-Timeframe, #refreshTimer {
  color: gainsboro;
  text-align: center;
  background-color: transparent;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  appearance:none;
  border:none;
  cursor: pointer;
}

#Fork-Timeframe:hover, #refreshTimer:hover {
  color:orange;
}

.option {
  background-color: #282923;
}

#SingleFork-Refresh {
  float: right;
}


/* TABLE */
.ForkTable {
  text-align: left;
  padding : 0px;
  margin: 10px auto;
  width: 70%;
  min-width: 1000px;
  background-color: #0c0c0d;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20); /* Stacked box shadows, pseudo-Material */
}

.TableNav {
  padding: 6px 0 6px 0;
  justify-content: center;
  align-items: center;
  display: flex;
  user-select: none;
}

.TableNav button {
  color: orange;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.TableNav span {
  margin: 0 0 0 12px;
}

.TableNav select {
  color: gainsboro;
  font-size: 16px;
  background-color: transparent;
  appearance: none;
  border: none;
  margin: 0 12px 0 12px;
}

.TableNav select:hover {
  color:orange;
  cursor: pointer;
}

.TableNav option {
  background-color: #282923;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

th {
  user-select: none;
}

td, th {
  border: none;
  text-align: center;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #2c2c2d;
}


/* CARDS */
.Fork-Wrapper, .Block-Wrapper {
  text-align: left;
  padding : 10px;
  margin: 10px auto;
  max-width: 1000px;
  background-color: #0c0c0d;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20); /* Stacked box shadows, pseudo-Material */
}

.Fork-Timestamp, .Block-Timestamp {
  font-size: 12px;
  user-select: none;
}

.Fork-Details {
  text-align: center; 
}

.SingleFork-Blurbs, .Summary-Blurbs {
  justify-content: center;
  align-items: center;
  display: flex;
}

.SingleFork-Title {
  width: 530px;
  background-color: #0c0c0d;
  padding: 10px;
  margin: 10px auto 0 auto;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20);
}

.SingleFork-SubTitle {
  color: grey;
  user-select: none;
}

.Blurb-Header {
  user-select: none;
  background-color: #0c0c0d;
  min-width: 150px;
  min-height: 100px;
  padding: 10px;
  margin: 10px 10px 0;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20);
}

.Blurb-SubNum, .Blurb-SubText {
  margin-top: 10%;
  display: flex;
  justify-content: center;
}

.Blurb-SubNum {
  color: orange;
}

.Blurb-SubText {
  font-weight: bold;
}

.Block-Hash, .Block-Creator {

}

.Block-Details {
  user-select: none;
  color: grey;
}

.Block-Friends {
  margin-left: 10px;
  font-size: 12px;
}

.Block-Hash, .Block-Creator {
}

.hidden {
  display: none;
}


/* LINKS, ALL */
a:link { /* Standard Link */
  user-select: none;
  color: gainsboro;
  font-style: italic;
}

a:visited { /* Visited link */
  color: gainsboro;
}

a:hover { /* Mouse-over link */
  color: grey;
}

a:active { /* Selected link */
  color: dimgrey;
}


/* REACT STUFF */
body {
  background-color: #282923;
}

.App {
  min-height: 100vh;
  font-family: 'Open Sans', sans-serif;
  color: gainsboro;
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

